import { Dialog, Transition } from "@headlessui/react"
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/solid"
import {
  Loading,
  Modal,
  Notification,
  SignInTemplate,
  TextButton,
} from "blixify-ui-web/lib"
import { Link, navigate } from "gatsby"
import React, { Component } from "react"
import { connect } from "react-redux"
import packageJson from "../../../package.json"
import Footer from "../../components/base/Footer"
import Header from "../../components/base/Header"
import SEO from "../../components/base/seo"
import {
  resetPassword,
  signIn,
  signInWithGoogle,
  signInWithPhoneNumber,
  verifyOTP,
} from "../../store/actions/authActions"
import { authStateInterface } from "../../store/reducers/authReducer"
import { utilsStateInterface } from "../../store/reducers/utilsReducer"

interface Props {
  authStore: authStateInterface
  utilsStore: utilsStateInterface
}

export interface NotificationState {
  type: boolean
  title: string
  msg: string
}

interface State {
  loading: boolean
  provider: "email" | "phone" | "phoneOTP" | "google"
  notification: NotificationState | null
}

class SignIn extends Component<Props> {
  state: State = {
    loading: false,
    provider: "email",
    notification: null,
  }

  componentDidMount() {
    this.handleCheckAuthNavigator()
  }

  componentDidUpdate(prevProps: Props) {
    if (
      prevProps.authStore.userLoading !== this.props.authStore.userLoading ||
      prevProps.authStore.userAuth !== this.props.authStore.userAuth ||
      prevProps.authStore.user !== this.props.authStore.user
    ) {
      this.handleCheckAuthNavigator()
    }
  }

  handleCheckAuthNavigator = async () => {
    if (this.props.authStore.userAuth) {
      if (this.props.authStore.user) navigate("/auth/signUp")
      else navigate(this.props.utilsStore.tngAccess ? "/tngHome" : "/dashboard")
    }
  }

  handleLoading = (loading: boolean) => {
    this.setState({ loading })
  }

  handleSignInWithGoogle = async () => {
    this.handleLoading(true)
    await signInWithGoogle(
      this.props.utilsStore.firebase,
      this.props.utilsStore.googleAuthProvider
    )
    this.handleLoading(false)
  }

  handleComplete = async (value: any) => {
    const { type, email, password, phone, otp } = value

    if (this.state.provider === "email") {
      this.handleLoading(true)
      if (type === "signIn") {
        let signInError = await signIn(
          {
            email: email,
            password: password,
          },
          this.props.utilsStore.firebase
        )
        if (signInError) {
          this.setState({
            notification: {
              type: false,
              title: "Sign In Error",
              msg: signInError,
            },
          })
        }
      } else if (type === "resetPassword") {
        await resetPassword(email, this.props.utilsStore.firebase)
        this.setState({
          notification: {
            type: true,
            title: "Reset Password Link Sent",
            msg:
              "Password reset link has been sent to your email if your account exists",
          },
        })
      }
    } else if (this.state.provider === "phone") {
      const signInError = await signInWithPhoneNumber(
        phone,
        this.props.utilsStore.firebase,
        this.props.utilsStore.recaptchaProvider
      )
      if (signInError) {
        this.setState({
          notification: {
            type: true,
            title: "Phone OTP Failed",
            msg: signInError,
          },
        })
      } else {
        this.setState({
          provider: "phoneOTP",
          notification: {
            type: true,
            title: "Phone OTP Sent",
            msg: "OTP has been sent to your phone number",
          },
        })
      }
    } else if (this.state.provider === "phoneOTP") {
      this.handleLoading(true)
      const signInError = await verifyOTP(otp)
      if (signInError) {
        this.setState({
          notification: {
            type: false,
            title: "OTP Verification Failed",
            msg: "Your code may be incorrect, please try again",
          },
        })
      }
    }
    this.handleLoading(false)
  }

  renderLeftComponents = () => {
    return (
      <>
        <h3 className="text-lg font-medium text-white">Contact information</h3>
        <dl className="mt-8 space-y-6">
          <dt>
            <span className="sr-only">Phone number</span>
          </dt>
          <dd className="flex text-base text-white">
            <svg
              className="flex-shrink-0 w-6 h-6 text-green-50"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
              />
            </svg>
            <span className="ml-3">+(60) 13-731 1007</span>
          </dd>
          <dt>
            <span className="sr-only">Email</span>
          </dt>
          <dd className="flex text-base text-white">
            <svg
              className="flex-shrink-0 w-6 h-6 text-green-50"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
              />
            </svg>
            <span className="ml-3">info@arusoil.com</span>
          </dd>
        </dl>
      </>
    )
  }

  renderNotification = () => {
    const notification = this.state.notification
    if (notification) {
      const correctIcon = (
        <CheckCircleIcon className="w-10 h-10 text-primary-600" />
      )
      const incorrectIcon = (
        <XCircleIcon className="w-10 h-10 text-primary-600" />
      )
      return (
        <Notification
          icon={notification.type ? correctIcon : incorrectIcon}
          notificationAttribute={{
            title: notification.title,
            desc: notification.msg,
            visible: true,
          }}
          lib={{
            Transition,
          }}
          onClose={() => {
            this.setState({ notification: null })
            return ""
          }}
        />
      )
    }
    return null
  }

  render() {
    if (this.props.authStore.userLoading) {
      return null
    }

    return (
      <div className="bg-warm-gray-50">
        <SEO
          title="Arus Oil - Sign In"
          description="Sign in an account in Arus Oil now. This allows you to view daily purchase rates of used cooking oil,contact logistic for collection and view payment made to you."
        />
        <Header page="Sign In" />
        <Modal
          open={this.state.loading}
          lib={{
            Dialog,
            Transition,
          }}
          renderContent={() => {
            return (
              <div className="flex flex-col items-center">
                <Loading />
                <p className="text-sm text-gray-700 mt-2">
                  Please hold on while we are verifying your account
                </p>
              </div>
            )
          }}
          onClose={() => {}}
        />
        {this.renderNotification()}
        <main className="overflow-hidden">
          <div className="py-12">
            <div className="relative z-10 max-w-7xl mx-auto pl-4 pr-8 sm:px-6 lg:px-8">
              <h1 className="mb-2 text-4xl font-extrabold tracking-tight text-warm-gray-900 sm:text-5xl">
                Sign In
              </h1>
              <Link
                to="/auth/signUp"
                className="font-bold text-lg text-primary-600 hover:text-primary-500"
              >
                Don't Have An Account ?
              </Link>
            </div>
          </div>

          <div className="mb-12 w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="bg-white">
              <SignInTemplate
                version={packageJson.version}
                bottomComponents={
                  this.props.utilsStore.tngAccess ? (
                    <TextButton
                      text="Login As Guest ?"
                      onClick={() => {
                        navigate("/tngHome")
                      }}
                    />
                  ) : undefined
                }
                leftComponents={this.renderLeftComponents()}
                leftClassName="bg-gradient-to-b from-arusgreen-500 to-arusgreen-600"
                type={this.state.provider}
                provider={
                  this.props.utilsStore.tngAccess
                    ? ["email"]
                    : ["email", "google", "phone"]
                }
                onChangeProvider={provider => {
                  if (provider === "google") {
                    this.handleSignInWithGoogle()
                  } else {
                    this.setState({ provider })
                  }
                }}
                onComplete={this.handleComplete}
              />
            </div>
          </div>
        </main>
        {!this.props.utilsStore.tngAccess && <Footer />}
      </div>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    authStore: state.authStore,
    utilsStore: state.utilsStore,
  }
}

export default connect(mapStateToProps)(SignIn)
